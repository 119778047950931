import React, { useEffect } from "react";

const GTranslateWidget = () => {
  useEffect(() => {
    // Define the gtranslateSettings object
    window.gtranslateSettings = {
      default_language: 'en',
      detect_browser_language: true,
      wrapper_selector: '.gtranslate_wrapper',
      switcher_background_color: "#110f0e",
      switcher_background_hover_color: "#110f0e",
      switcher_border_color: "#110f0e",
      switcher_background_shadow_color: "#110f0e",
      dropdown_background_color: "#110f0e",
      dropdown_text_color: "#f2f0e4",
      dropdown_hover_color: "#757272",

    };

    // Load the gtranslate script asynchronously
    const script = document.createElement('script');
    script.src = 'https://cdn.gtranslate.net/widgets/latest/dwf.js';
    script.defer = true;
    document.body.appendChild(script);

    return () => {
      // Cleanup: remove the script when the component unmounts
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className="gtranslate_wrapper"></div>
  );
};

const MemoizedGTranslateWidget = () => {
  return (
    <div>
      <GTranslateWidget />
    </div>
  );
};

export default MemoizedGTranslateWidget;
