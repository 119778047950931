
const Footer = () => {
  const year = new Date().getFullYear()
  return (
      <div className="footer">
        <span>© 2017-{year} <span id="footerLogo">swiftyvisas</span>. All rights reserved</span>
      </div>
  );
};

export default Footer;
