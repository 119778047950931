import { Carousel, Col, Container, Row } from "react-bootstrap";
import man from "../assets/man.jpg";
import pic1 from "../assets/pic1.webp";
import pic2 from "../assets/pic2.jpg";
import pic3 from "../assets/pic3.jpg";
import pic4 from "../assets/pic4.webp";
import pic5 from "../assets/pic5.webp";
import pic6 from "../assets/pic6.webp";
import pic7 from "../assets/pic7.jpg";
import pic8 from "../assets/pic8.jpg";

const Testimony = () => {
  return (
    <Container>
      <h1 className="aboutText testimonyText">
        <span id="aboutText1">What Users Say </span>
        <span id="aboutText2">About Us</span>
      </h1>

      <Carousel>
        <Carousel.Item>
          <Row id="yam">
            <Col>
              <div className="chooseUs" id="testimonyCard">
                <img src={pic6} alt="man" id="testimonyImg" oading="lazy" />
                <h4 id="chooseUsTitle">Nancy Serrano</h4>
                I've been a part of this platform for a while now, and I'm truly
                impressed. The returns are consistent, and I've had no issues
                with withdrawals. 
              </div>
            </Col>
            <Col>
              <div className="chooseUs" id="testimonyCard">
                <img src={pic1} alt="man" id="testimonyImg" oading="lazy" />
                <h4 id="chooseUsTitle">Joanna Pepple</h4>I was initially
                skeptical, but after investing, I've seen real profits. This
                platform is the real deal, and I'm excited about the growth
                potential.
              </div>
            </Col>

            <Col className="last">
              <div className="chooseUs" id="testimonyCard">
                <img src={pic7} alt="man" id="testimonyImg" oading="lazy" />
                <h4 id="chooseUsTitle">Jonathan Waller</h4>I have invested with
                As an experienced investor, I can confidently say that this
                platform is a gem. They deliver on their promises, and the
                customer support is top-notch.
              </div>
            </Col>
          </Row>
        </Carousel.Item>
        <Carousel.Item>
          <Row>
            <Col>
              <div className="chooseUs" id="testimonyCard">
                <img src={pic5} alt="man" id="testimonyImg" oading="lazy" />
                <h4 id="chooseUsTitle">Pauline Harper </h4>
                Investing here has been a game-changer for me. It's not just
                about the returns; it's about the peace of mind knowing my
                investments are in good hands
              </div>
            </Col>
            <Col>
              <div className="chooseUs" id="testimonyCard">
                <img src={pic8} alt="man" id="testimonyImg" oading="lazy" />
                <h4 id="chooseUsTitle">Terry Walter</h4>
                I've seen my investments grow steadily since I joined. This
                platform is a fantastic opportunity for anyone looking to build
                wealth.
              </div>
            </Col>
            <Col className="last">
              <div className="chooseUs" id="testimonyCard">
                <img src={pic4} alt="man" id="testimonyImg" oading="lazy" />
                <h4 id="chooseUsTitle">Jillian Slater</h4>
                I've recommended this platform to friends, and we've all seen
                positive results. It's a reliable way to grow your capital..
              </div>
            </Col>
          </Row>
        </Carousel.Item>
        {/* <Carousel.Item>
          <Row>
            <Col >
              <div className="chooseUs" id="testimonyCard">
                <img src={pic6} alt="man" id="testimonyImg" oading="lazy" />
                <h4 id="chooseUsTitle">Nancy Serrano</h4>
                Mi gerente de cuenta me trajo aquí, merece un elogio. Con $1,000
                en bitcoins, obtuve $5,000 la primera semana y otros $5,000 la
                segunda semana. Ahora he pagado mis deudas y estoy feliz
              </div>
            </Col>
            <Col >
              <div className="chooseUs" id="testimonyCard">
                <img src={pic5} alt="man" id="testimonyImg" oading="lazy"/>
                <h4 id="chooseUsTitle">Pauline Harper </h4>
                We are trusted by a huge number of people. We are working hard
                constantly to improve the level of our security system and
                minimize possible risks.
              </div>
            </Col>
            <Col className="last">
              <div className="chooseUs" id="testimonyCard">
                <img src={pic7} alt="man" id="testimonyImg" oading="lazy"/>
                <h4 id="chooseUsTitle">Jonathan Waller</h4>
                Our all retreats are treated spontaneously once requested. There
                are high maximum limits. The minimum withdrawal amount is only
                $10 .
              </div>
            </Col>
          </Row>
        </Carousel.Item> */}
      </Carousel>
    </Container>
  );
};

export default Testimony;
