import { Button, Col, Container, Form, Row } from "react-bootstrap";
import office from "../assets/office.png";
import letter from "../assets/letter.png";
import whatsapp from "../assets/whatsapp.png";

const ContactUs = () => {
  return (
    <Container id="contactUs">
      <h1 className="aboutText">
        <span id="aboutText1">Contact </span>
        <span id="aboutText2">Us</span>
      </h1>
      <Row className="contactContainer">
        <Col className="contactRow">
          <Form>
            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label id="contactLabel">Name</Form.Label>
              <Form.Control type="text" placeholder="Enter full name" />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label id="contactLabel">Email address</Form.Label>
              <Form.Control type="email" placeholder="Enter email" />
              <Form.Text className="text-muted">
                We'll never share your email with anyone else.
              </Form.Text>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label id="contactLabel">Message</Form.Label>
              <Form.Control as="textarea" placeholder="Enter message" />
            </Form.Group>

            <button type="submit" id="contactBtn">
              SEND
            </button>
          </Form>
        </Col>
        <Col>
          <div className="contactInfo">
            <ul className="contactWrapper">
              <li>
                <h2>Info</h2>
              </li>
              <li>
                <img
                  src={letter}
                  alt="letter"
                  id="contactIcon"
                  width="100%"
                  height="100%"
                />
                <span>support@swiftyvisas.com</span>
              </li>
              <li>
                <img
                  src={whatsapp}
                  alt="letter"
                  id="contactIcon"
                  width="100%"
                  height="100%"
                />
                <span>+1928-4915-958</span>
              </li>
              <li>
                <img
                  src={office}
                  alt="letter"
                  id="contactIcon"
                  width="100%"
                  height="100%"
                />
                <span>
                  {" "}
                  500 N 16th Street San Jose, CA 95112-1730 USA
                </span>
              </li>
            </ul>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default ContactUs;
