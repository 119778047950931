import { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { NavLink, useNavigate } from "react-router-dom";
import avatar from "../assets/avatar.png";
import { getLocalData } from "../services/auth";
import TranslateBtn from "./TranslateBtn";
import logoImg from "../assets/logoImg.png";

function TopNavigationBar() {
  const navigate = useNavigate();
  const [userData, setUserData] = useState([]);

  useEffect(() => {
    const fetchedData = JSON.parse(getLocalData("userData"));
    setUserData(fetchedData);
  }, []);

  const handlePage = () => {
    if (userData) {
      navigate("/dashboard");
    } else {
      navigate("/login");
    }
  };

  return (
    <>
      <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
        <Container fluid>
          <div to="/" style={{ width: "40%" }}>
            <Navbar.Brand href="#">
              {" "}
              <strong>
                <span translate="no" id="aboutText1">
                  <img src={logoImg} alt="logo" id="logoImg" />
                </span>
              </strong>
            </Navbar.Brand>
          </div>

          <Navbar.Toggle />
          <Navbar.Collapse>
            <Nav className="justify-content-end flex-grow-1 pe-3" style={{marginRight: "70px"}}>
              <div className="loggedNav">
                <Nav.Link href="#banner">Home</Nav.Link>
                <Nav.Link href="#InvestmentPlans">Plan</Nav.Link>
                <Nav.Link href="#aboutSection">About</Nav.Link>
                <Nav.Link href="#contactUs">Contact</Nav.Link>

                <img
                  src={avatar}
                  alt="avatar"
                  id="avatar"
                  onClick={handlePage}
                />
              </div>
            </Nav>

            <TranslateBtn />
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default TopNavigationBar;
