import { useEffect, useRef, useState } from "react";
import { createContext } from "react";
import {
  HashRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import AdminTransactions from "./Pages/AdminTransactions";
import ChangePassword from "./Pages/ChangePassword";
import Dashboard from "./Pages/Dashboard";
import HomePage from "./Pages/HomePage";
import Login from "./Pages/Login";
import Plans from "./Pages/Plans";
import Profile from "./Pages/Profile";
import Referral from "./Pages/Referral";
import ResetPassword from "./Pages/ResetPassword";
import SignUp from "./Pages/SignUp";
import Support from "./Pages/Support";
import Users from "./Pages/Users";
import { getLocalData, setLocalData } from "./services/auth";
import PaymentPage from "./Pages/PaymentPage";
// import Modal from "react-bootstrap/Modal";
// import { Button } from "react-bootstrap";
// import CountDown from "./components/CountDown";
// import MiniCountDown from "./components/MiniCountDown";
// import WhatsappChatWidget from "./components/WhatsAppWidget";

export const dashboardContext = createContext(null);
export const userContext = createContext(null);

const App = () => {
  const [userData, setUserData] = useState([]);
  const [isPendingInvestment, setIsPendingInvestment] = useState(false);

  const storedShowState = localStorage.getItem('showState');
  const initialShowState = storedShowState ? JSON.parse(storedShowState) : false;

  const [show, setShow] = useState(initialShowState);



  // ------------ Fetch updated user ----------------------
  useEffect(() => {
    const localData = JSON.parse(getLocalData("userProfile"));

    if (userData) {
      const handleFreshUserDetails = async () => {
        const res = await (
          await fetch("https://swifty-api.swiftyvisas.com/api/v1/getuserdata", {
            method: "POST",

            headers: {
              "content-type": "application/json",
            },
            body: JSON.stringify({
              _id: localData.id,
            }),
          })
        ).json();

        setUserData(res);
        setLocalData("userData", res);
      };
      handleFreshUserDetails();
    }
  }, []);

  // -------------- Check if user status is active, else log user out---------------------------
  useEffect(() => {
    if (
      (userData.profile && userData.profile.status === "blocked") ||
      userData.msg
    ) {
      localStorage.removeItem("userData");
      localStorage.removeItem("newTransaction");
      localStorage.removeItem("userProfile");
      localStorage.removeItem("recentTransaction");

      window.location.href = "/";
    }
  }, [userData]);

 const handleClose = () => {
    setShow(false);
    localStorage.setItem('showState', JSON.stringify(false));
  };

  useEffect(() => {
    setTimeout(() => {
      setShow(true);
      localStorage.setItem('showState', JSON.stringify(true));
    }, 7000);
  }, []);

  return (
    <>
      {/* <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Website Update Notice</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            We're currently working on some exciting updates to improve your
            experience. Feel free to check the countdown for the upcoming
            launch. Thank you for your understanding. Meanwhile, you're welcome
            to continue making investments.
          </p>

          <CountDown />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal> */}

      {/* <MiniCountDown /> */}

      {/* <WhatsappChatWidget /> */}

      <Router>
        <Routes>
          <Route path="/" element={<HomePage />} />{" "}
          <Route
            path="/login"
            element={
              <dashboardContext.Provider value={{ userData, setUserData }}>
                <Login />
              </dashboardContext.Provider>
            }
          />{" "}
          <Route
            path="/register"
            element={
              <dashboardContext.Provider value={{ userData, setUserData }}>
                <SignUp />
              </dashboardContext.Provider>
            }
          />{" "}
          <Route path="/resetpassword" element={<ResetPassword />} />{" "}
          <Route
            path="/dashboard"
            element={
              <dashboardContext.Provider value={{ userData, setUserData }}>
                <Dashboard />
              </dashboardContext.Provider>
            }
          />{" "}
          <Route
            path="/plans"
            element={
              <Plans
                isPendingInvestment={isPendingInvestment}
                setIsPendingInvestment={setIsPendingInvestment}
                userData={userData}
              />
            }
          />{" "}
          <Route path="/referrals" element={<Referral />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/users" element={<Users />} />{" "}
          <Route path="/transactions" element={<AdminTransactions />} />{" "}
          <Route path="/changepassword" element={<ChangePassword />} />{" "}
          <Route path="/support" element={<Support />} />{" "}
          <Route path="/payment" element={<PaymentPage />} />{" "}
        </Routes>{" "}
      </Router>
    </>
  );
};

export default App;
