import React from 'react';

const TradingViewChart = () => {
  return (
    <iframe
      title="advanced chart TradingView widget"
      lang="en"
      id="tradingview_6cd4e"
      frameBorder="0"
      allowTransparency="true"
      scrolling="no"
      allowFullScreen="true"
      src={`https://s.tradingview.com/widgetembed/?hideideas=1&amp;overrides=%7B%7D&amp;enabled_features=%5B%5D&amp;disabled_features=%5B%5D&amp;locale=en#%7B%22symbol%22%3A%22NASDAQ%3AAAPL%22%2C%22frameElementId%22%3A%22tradingview_6cd4e%22%2C%22interval%22%3A%225%22%2C%22allow_symbol_change%22%3A%221%22%2C%22save_image%22%3A%221%22%2C%22studies%22%3A%22%5B%5D%22%2C%22theme%22%3A%22dark%22%2C%22style%22%3A%221%22%2C%22timezone%22%3A%22Etc%2FUTC%22%2C%22studies_overrides%22%3A%22%7B%7D%22%2C%22utm_source%22%3A%22financial-change.com%22%2C%22utm_medium%22%3A%22widget_new%22%2C%22utm_campaign%22%3A%22chart%22%2C%22utm_term%22%3A%22NASDAQ%3AAAPL%22%2C%22page-uri%22%3A%22financial-change.com%2F%22%7D`}
      style={{ width: '100%', height: '100%', margin: '0px !important', padding: '0px !important' }}
    ></iframe>
  );
};

export default TradingViewChart;
