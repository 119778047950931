import React, { useMemo } from 'react';

const TradePrice = () => {
  const marqueeWidget = useMemo(() => (
    <coingecko-coin-price-marquee-widget
      coin-ids="bitcoin,ethereum,eos,ripple,litecoin"
      currency="usd"
      locale="en"
      background-color="#000000"
    ></coingecko-coin-price-marquee-widget>
  ), []);

  return (
    <div>
      {marqueeWidget}
    </div>
  );
};

export default TradePrice;
