import { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { getLocalData } from "../services/auth";

const HeroSection = () => {
  const navigate = useNavigate();
  const [userData, setUserData] = useState([]);

  useEffect(() => {
    const fetchedData = JSON.parse(getLocalData("userData"));
    setUserData(fetchedData);
  }, []);

  const handlePage = () => {
    if (!userData) return navigate("/login");

    if (userData.profile && userData.profile) return navigate("/dashboard");
  };

  return (
    <>
      <div className="rounded-3" id="hero-bg">
        <div className="mask">
          <h1>Invest in a stable platform for the future</h1>
          <h1>And make money fast</h1>
          <button id="hero-btn" onClick={handlePage}>Start Earning</button>
        </div>
      </div>
    </>
  );
};

export default HeroSection;
