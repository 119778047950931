import LoggedInNavbar from "../components/LoggedInNavbar";
import Footer from "../components/Footer";
import InvestmentPlans from "../components/InvestmentPlans";
import { useEffect, useState } from "react";

const Plans = ({ isPendingInvestment, userData, setIsPendingInvestment }) => {
  const year = new Date().getFullYear();

  useEffect(() => {
    document.title = "swiftyvisas | Plans";
  }, []);


  return (
    <>
      <LoggedInNavbar />
      <InvestmentPlans
        isPendingInvestment={isPendingInvestment}
        setIsPendingInvestment={setIsPendingInvestment}
        userData={userData}
      />
      {/* <Footer /> */}

      <div className="homeFooter">
        <span>
          © 2017-{year} <span id="footerLogo">swiftyvisas</span>. All rights
          reserved
        </span>
      </div>
    </>
  );
};

export default Plans;
