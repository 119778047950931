import { Col, Container, Row } from "react-bootstrap";
import legal from "../assets/legal.png";
import reliable from "../assets/reliable.png";
import withdrawal from "../assets/withdrawal.png";
import support from "../assets/support.png";
import anonim from "../assets/anonim.png";
import server from "../assets/server.png";
import ssl from "../assets/ssl.png";
import dd from "../assets/dd.png";

const WhyChooseUs = () => {
  return (
    <Container fluid>
      <h1 className="whyChooseUsText">
        <span id="aboutText1">Why Choose </span>
        <span id="aboutText2">swiftyvisas</span>
      </h1>

      <Row className="chooseUsSection">
        <Col sm={12} lg={3}>
          <div className="chooseUs">
            <img src={legal} alt="legal" id="chooseUsIcon" />
            <h4 id="chooseUsTitle">Legal Company</h4>
            Our company conducts absolutely legal activities in the legal field.
            We are certified to operate investment business, we are legal and
            safe.
          </div>
        </Col>
        <Col sm={12} lg={3}>
          <div className="chooseUs">
            <img src={reliable} alt="legal" id="chooseUsIcon" />
            <h4 id="chooseUsTitle">Reliable</h4>
            We are trusted by a huge number of people. We are working hard
            constantly to improve the level of our security system and minimize
            possible risks.
          </div>
        </Col>
        <Col sm={12} lg={3}>
          <div className="chooseUs">
            <img src={withdrawal} alt="legal" id="chooseUsIcon" />
            <h4 id="chooseUsTitle">Quick Withdrawal</h4>
            Our all retreats are treated spontaneously once requested. There are
            high maximum limits. The minimum withdrawal amount is only $50 .
          </div>
        </Col>
        <Col sm={12} lg={3}>
          <div className="chooseUs">
            <img src={support} alt="legal" id="chooseUsIcon" />
            <h4 id="chooseUsTitle">24/7 Support</h4>
            We provide 24/7 customer support through e-mail and telegram. Our
            support representatives are periodically available to elucidate any
            difficulty.
          </div>
        </Col>

        <Col sm={12} lg={3}>
          <div className="chooseUs">
            <img src={anonim} alt="legal" id="chooseUsIcon" />
            <h4 id="chooseUsTitle">Anonymity</h4>
            Anonymity and using cryptocurrency as a payment instrument. In the
            era of electronic money – this is one of the most convenient ways of
            cooperation.
          </div>
        </Col>
        <Col sm={12} lg={3}>
          <div className="chooseUs">
            <img src={server} alt="legal" id="chooseUsIcon" />
            <h4 id="chooseUsTitle">Dedicated Server</h4>
            We are using a dedicated server for the website which allows us
            exclusive use of the resources of the entire server.
          </div>
        </Col>
        <Col sm={12} lg={3}>
          <div className="chooseUs">
            <img src={ssl} alt="legal" id="chooseUsIcon" />
            <h4 id="chooseUsTitle">SSL Secured</h4>
            Comodo Essential-SSL Security encryption confirms that the presented
            content is genuine and legitimate.
          </div>
        </Col>
        <Col sm={12} lg={3}>
          <div className="chooseUs">
            <img src={dd} alt="legal" id="chooseUsIcon" />
            <h4 id="chooseUsTitle">DDOS Protection</h4>
            We are using one of the most experienced, professional, and trusted
            DDoS Protection and mitigation provider.
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default WhyChooseUs;
