import { Col, Container, Row } from "react-bootstrap";
import aboutImg2 from "../assets/meeting2.webp"
import meeting from "../assets/meetingPeople.webp"

const AboutSection = () => {
  return (
    <Container fluid className="aboutSection" id="aboutSection">
      <Row className="aboutRow">
        <Col className="col-xl-6 ">
          <img src={meeting} alt="company meeting" id="aboutImg" srcSet={meeting} oading="lazy" />
        </Col>
        <Col className="col-xl-6 ">
          <h1 className="aboutText">
            <span id="aboutText1">About </span>
            <span id="aboutText2">Us</span>
          </h1>

          <p id="aboutTextParagraph">
            We are international financial company engaged in investment
            activities, that are concerning commerce on financial markets and
            cryptocurrency exchanges performed by qualified skilled traders.
          </p>

          <p id="aboutTextParagraph">
            Our goal is to provide our investors with a reliable source of high
            income, while minimizing potential risks and providing a quality
            service that allows us to automate and simplify investor-custodial
            relationships. We work to increase your profit margin through a
            profitable investment. We hope you'll be part of our community.
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default AboutSection;
