import { useEffect, useRef } from "react";
import { useState } from "react";
import {
  Alert,
  Col,
  Container,
  Modal,
  OverlayTrigger,
  Row,
  Tooltip,
} from "react-bootstrap";
import { useNavigate } from "react-router";
import clip from "../assets/clip.png";
import Loader from "./Loader";

const InvestmentPlans = ({ userData }) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [show, setShow] = useState(false);
  const target = useRef(null);
  const [toShow, setToShow] = useState(false);

  const [lastTransaction, setLastTransaction] = useState("");
  const [isPendingInvestment, setIsPendingInvestment] = useState(false);
  const [userExist, setUserExist] = useState([]);

  const [plans, setPlans] = useState([
    {
      id: 0,
      type: "Silver",
      percent: "10",
      days: 5,
      total: 10,
      amount: 500,
    },
    {
      id: 1,
      type: "Ruby",
      percent: "20",
      days: 10,
      total: 20,
      amount: 2500,
    },
    {
      id: 2,
      type: "Gold",
      percent: "20",
      days: 15,
      total: 20,
      amount: 7500,
    },
    {
      id: 3,
      type: "Diamond",
      percent: "20",
      days: 20,
      total: 20,
      amount: 15500,
    },
  ]);

  const [toCopy, setToCopy] = useState([
    {
      id: 0,
      title: "USDT (TRC20) Address",
      address: "TVrkeWTXQmc5qCaQg7BGE1qwiX8hcFeAY2",
    },
    {
      id: 1,
      title: "Ethereum Address",
      address: "0x8C012eB1ED57e5E521460924746424BeA10e3a9A",
    },
    {
      id: 2,
      title: "BTC Address",
      address: "bc1q599vapzsdnf48qykmlcx083j8zrtgscnvh0r3u",
    },
    {
      id: 3,
      title: "Payment Reference",
      address: "",
    },
  ]);

  const [toAccess, setToAccess] = useState(0);

  useEffect(() => {
    const data = localStorage.getItem("userProfile");
    setUserExist(data);
  }, []);

  const handleClose = () => {
    setToShow(false);
    setIsLoading(false);

    navigate("/dashboard");
  };

  // -------------- Update current transaction on  pageload ---------------------
  useEffect(() => {
    const allTransaction = async () => {
      const updatedTransaction = userData.transaction;
      const allTransactionStatus = [];

      updatedTransaction.map(({ status }) => {
        allTransactionStatus.push(status);

        setIsPendingInvestment(allTransactionStatus.includes("pending"));
      });

      const pending =
        updatedTransaction[updatedTransaction.length - 1].status === "pending"
          ? true
          : false;
      setIsPendingInvestment(pending);
    };

    allTransaction();
  }, [userData]);

  // ---------------- Handle Invest ------------------------------
  // const handleShow = async (id, amount) => {
  //   setToAccess(id);
  //   setIsLoading(true);

  //   if (isPendingInvestment === true) {
  //     setToShow(true);
  //     return;
  //   }

  //   if (!userData) return navigate("/login");

  //   const res = await (
  //     await fetch("https://swifty-api.swiftyvisas.com/api/v1/invest", {
  //       method: "POST",

  //       headers: {
  //         "content-type": "application/json",
  //       },
  //       body: JSON.stringify({
  //         _id: userData.profile.id,
  //         amount,
  //       }),
  //     })
  //   ).json();

  //   setLastTransaction(res[res.length - 1].referenceId);
  //   setToShow(true);
  // };

  const handlePaymentPage = (amount, type) => {
    if (!userExist) return navigate("/login");

    navigate("/payment", { state: { amount, type, userData } });
  };

  // ============ Design Logics =====================
  function copy(e) {
    navigator.clipboard.writeText(e);
    setShow(!show);
  }

  const modal = ({ type, amount }) => {
    return (
      <Modal show={toShow} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Payment Address</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Alert variant="success">
            <Alert.Heading>Well done!</Alert.Heading>
            <p>
              Your{" "}
              <strong>
                ${amount.toLocaleString()} {type} plan
              </strong>{" "}
              investment request was successful. However, this investment will
              become active if payment is confirmed.
            </p>
            <hr />
            <p className="mb-0">
              Please make your payment to any of the Wallet Addresses below, and
              use the Payment Reference as remark.
            </p>
            <p className="mb-0">
              All payments are confirmed within 30-60 mins after the payment was
              made.
            </p>
            <br />

            <div className="codes">
              {toCopy.map(({ id, title, address }) => (
                <div key={id}>
                  <h6>{title}</h6>
                  <div className="clipSection" id="clipwrapper">
                    <input
                      type="text"
                      readOnly
                      className="clipboardinput"
                      value={id === 3 ? lastTransaction : address}
                      style={{
                        outline: "1px solid rgba(153,205,50,.514)",
                        backgroundColor: "rgba(153,205,50,.514)",
                      }}
                    />

                    <div
                      className="clipIconHolder"
                      ref={target}
                      onClick={() => copy(id === 3 ? lastTransaction : address)}
                      style={{ backgroundColor: "#3e5217", outline: "#3e5217" }}
                    >
                      <OverlayTrigger
                        trigger="click"
                        key="top"
                        placement="top"
                        overlay={
                          <Tooltip id={`tooltip-$"top"`}>Copied!</Tooltip>
                        }
                      >
                        <img src={clip} alt="clip" id="clipIcon" />
                      </OverlayTrigger>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </Alert>
        </Modal.Body>
      </Modal>
    );
  };

  const rejectModal = () => {
    return (
      <Modal show={toShow} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Alert variant="danger">
            <Alert.Heading>Pending payment</Alert.Heading>
            <p>
              Sorry, you have a pending payment, please proceed with the
              payment. If you have already made a payment and are still seeing
              this, please open a support ticket.
            </p>
          </Alert>
        </Modal.Body>
      </Modal>
    );
  };

  return (
    <Container id="InvestmentPlans" fluid>
      <h1 className="aboutText">
        <span id="aboutText1">Investment </span>
        <span id="aboutText2">Plans</span>
      </h1>

      {/* ========== Modal Section =================  */}
      {isPendingInvestment ? rejectModal() : modal(plans[toAccess])}

      <Row className="plansRow">
        {plans.map(({ id, type, percent, days, total, amount }) => (
          <Col key={id}>
            <div className="planCard">
              <h2>{type}</h2>
              <br />
              <span>Return {percent}%</span>
              <br />
              <hr />
              <span>After {days} Days</span>
              <br />
              <hr />
              <span>Total {total}% + Capital</span>
              <hr />
              <h3>${amount.toLocaleString()}</h3>

              <button
                className="startPlanBtn"
                // onClick={() => handleShow(id, amount)}
                onClick={() => handlePaymentPage(amount, type)}
                disabled={isLoading}
              >
                {isLoading && toAccess === id ? <Loader /> : "Start Earning"}
              </button>
            </div>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default InvestmentPlans;
