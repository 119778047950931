import { useEffect, useState } from "react";
import { Container, Table } from "react-bootstrap";
import Footer from "../components/Footer";
import LoggedInNavbar from "../components/LoggedInNavbar";
import { getLocalData } from "../services/auth";
import FetchLoader from "../components/FetchLoader";
import InstaLoader from "../components/InstaLoader";

const Referral = () => {
  const [userData, setUserData] = useState([]);
  const [isloading, setIsloading] = useState(false);

  useEffect(() => {
    document.title = "swiftyvisas | Referral";
  }, []);

  useEffect(() => {
    const fetchedData = JSON.parse(getLocalData("userData"));
    setUserData(fetchedData);
  }, []);

  // ------------ Fetch updated user ----------------------
  useEffect(() => {
    const localData = JSON.parse(getLocalData("userData"));
    setIsloading(true);

    if (userData) {
      const handleFreshUserDetails = async () => {
        const res = await (
          await fetch("https://swifty-api.swiftyvisas.com/api/v1/getuserdata", {
            method: "POST",

            headers: {
              "content-type": "application/json",
            },
            body: JSON.stringify({
              _id: localData.profile.id,
            }),
          })
        ).json();

        setUserData(res);
        setIsloading(false);
      };
      handleFreshUserDetails();
    }
  }, []);

  return (
    <>
      <LoggedInNavbar isFetching={isloading} />
      <Container className="referralPage" fluid>
        <Table responsive>
          <tr>
            <th>S/N</th>
            <th>Fullname</th>
            <th>Joined At</th>
            <th>Status</th>
          </tr>

          {userData.referrals &&
            userData.referrals.map(
              ({ firstName, secondName, status, createdAt }, index) => (
                <tr>
                  <td>{index + 1} </td>
                  <td>{firstName} {secondName}</td>
                  <td>{createdAt.split("T")[0]}</td>
                  <td>{status}</td>
                </tr>
              )
            )}
        </Table>
      </Container>
      <Footer />
    </>
  );
};

export default Referral;
