import { useEffect, useState } from "react";
import { Col, Row, Container, Form } from "react-bootstrap";
import Footer from "../components/Footer";
import LoggedInNavbar from "../components/LoggedInNavbar";
import { getLocalData, setLocalData } from "../services/auth";
import Loader from "../components/Loader";
import Notification from "../components/Notification";

const Profile = () => {
  const [profileImg, setProfileImg] = useState("");
  const [userData, setUserData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [color, setColor] = useState("");
  const [showAlert, setShowAlert] = useState(false);

  const [firstName, setFirstName] = useState("");
  const [secondName, setSecondName] = useState("");
  const [phone, setPhone] = useState("");
  const [country, setCountry] = useState("");
  const [cryptoWalletAddress, setCryptoWalletAddress] = useState("");
  const [cryptoWalletNetwork, setCryptoWalletNetwork] = useState("");
  const [imgUpdated, setImgUpdated] = useState(false);
  const [imgSize, setImgSize] = useState(0);

  const [refresh, setRefresh] = useState(true);
  const year = new Date().getFullYear();

  useEffect(() => {
    document.title = "swiftyvisas | Profile";
  }, []);

  // -------------- Grab image an set the profile image ----------------------
  const handleAddProfile = (e) => {
    var reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);

    setImgSize(e.target.files[0].size);

    reader.onloadend = () => {
      setProfileImg(reader.result);
      setImgUpdated(true);
    };
  };

  useEffect(() => {
    const fetchedData = JSON.parse(getLocalData("userProfile"));
    setUserData(fetchedData);
    setProfileImg(fetchedData.profile);
  }, []);

  // ------------------ Handle crypto network selection --------------------------------
  const handleCryptoSelection = (e) => {
    setCryptoWalletNetwork(e.target.value);
  };

  // --------------- Handle updating of profile ---------------------------------------
  const handleUpdateProfile = async (e) => {
    e.preventDefault();

    setIsLoading(true);

    const data = {
      _id: userData.id,
      firstName,
      secondName,
      phone,
      country,
      cryptoWalletAddress,
      cryptoWalletNetwork,
      profileImg,
    };

    try {
      if (
        !firstName &&
        !secondName &&
        !phone &&
        !country &&
        !cryptoWalletAddress &&
        !cryptoWalletNetwork &&
        !imgUpdated
      ) {
        throw "Cannot update empty fields";
      } else if (profileImg && imgSize <= 1024) {
        const result = await (
          await fetch(
            "https://swifty-api.swiftyvisas.com/api/v1/updateprofile",
            {
              method: "POST",

              headers: {
                "content-type": "application/json",
              },
              body: JSON.stringify(data),
            }
          )
        ).json();

        setLocalData("userProfile", result);
        setRefresh(!refresh);

        setMessage("Profile updated successfully");
        setColor("success");
        setIsLoading(false);
        setShowAlert(true);

        setFirstName("");
        setSecondName("");
        setCountry("");
        setCryptoWalletAddress("");
        setCryptoWalletNetwork("");
        setPhone("");
        setImgUpdated(false);
      } else {
        const result = await (
          await fetch(
            "https://swifty-api.swiftyvisas.com/api/v1/updateprofile",
            {
              method: "POST",

              headers: {
                "content-type": "application/json",
              },
              body: JSON.stringify(data),
            }
          )
        ).json();

        setLocalData("userProfile", result);
        setRefresh(!refresh);

        setMessage("Profile updated successfully");
        setColor("success");
        setIsLoading(false);
        setShowAlert(true);

        setFirstName("");
        setSecondName("");
        setCountry("");
        setCryptoWalletAddress("");
        setCryptoWalletNetwork("");
        setPhone("");
        setImgUpdated(false);
      }
    } catch (error) {
      setMessage(error);
      setColor("danger");
      setShowAlert(true);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const fetchedData = JSON.parse(getLocalData("userProfile"));
    setUserData(fetchedData);
    setProfileImg(fetchedData.profile);
  }, [refresh]);

  return (
    <>
      <LoggedInNavbar />

      {showAlert ? (
        <Notification
          message={message}
          color={color}
          setShowAlert={setShowAlert}
        />
      ) : (
        ""
      )}

      <Container className="signupWrapper">
        <div className="profile">
          <Form onSubmit={handleUpdateProfile}>
            {/* --------------Avatar Section ------------ */}
            <Row>
              <Col>
                <div className="avatarWrapper">
                  <div
                    className="avatar"
                    style={{ backgroundImage: `url('${profileImg}')` }}
                  ></div>
                  <div className="addProfile">
                    <input
                      type="file"
                      id="profileImage"
                      accept=".jpg, .jpeg, .png"
                      onChange={(e) => handleAddProfile(e)}
                    />
                    <label htmlFor="profileImage" className="profileIcon">
                      <img
                        src="https://cdn-icons-png.flaticon.com/512/1159/1159633.png"
                        alt=""
                        className="editIcon"
                      />
                    </label>
                  </div>
                </div>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label id="formLabel">First Name</Form.Label>
                  <Form.Control
                    style={{ textTransform: "capitalize" }}
                    type="text"
                    placeholder={userData.firstName}
                    onInput={(e) => setFirstName(e.target.value)}
                    value={firstName}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label id="formLabel">Surname</Form.Label>
                  <Form.Control
                    style={{ textTransform: "capitalize" }}
                    type="text"
                    placeholder={userData.secondName}
                    onInput={(e) => setSecondName(e.target.value)}
                    value={secondName}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label id="formLabel">Username</Form.Label>
                  <Form.Control
                    type="text"
                    id="cantChange"
                    value={userData.userName}
                    readOnly
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label id="formLabel">Email</Form.Label>
                  <Form.Control
                    type="text"
                    id="cantChange"
                    value={userData.email}
                    readOnly
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label id="formLabel">Country</Form.Label>
                  <Form.Control
                    style={{ textTransform: "capitalize" }}
                    type="text"
                    placeholder={userData.country}
                    onInput={(e) => setCountry(e.target.value)}
                    value={country}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label id="formLabel">Phone Number</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder={userData.phone}
                    onInput={(e) => setPhone(e.target.value)}
                    value={phone}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row id="cryptoDetails">
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label id="formLabel">
                    Crypto Wallet Address Network
                  </Form.Label>
                  <Form.Select required onChange={handleCryptoSelection}>
                    {/* {userData.cryptoWalletNetwork === undefined ? (
                      <option id="cantChange">
                        {userData.cryptoWalletNetwork
                          ? userData.cryptoWalletNetwork
                          : "--select crypto network--"}
                      </option>
                    ) : (
                      ""
                    )} */}

                    <option>--select crypto network--</option>
                    <option>Bitcoin (BTC)</option>
                    <option>Ethereum (ETH)</option>
                    <option>Tether (USDT)</option>
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label id="formLabel">Crypto Wallet Address</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder={
                      userData.cryptoWalletAddress
                        ? userData.cryptoWalletAddress
                        : "Enter wallet address"
                    }
                    onInput={(e) => setCryptoWalletAddress(e.target.value)}
                    value={cryptoWalletAddress}
                  />
                </Form.Group>
              </Col>
            </Row>

            <button type="submit" id="logBtn" disabled={isLoading}>
              {isLoading ? <Loader /> : "Update"}
            </button>
          </Form>
        </div>
      </Container>
      {/* <Footer /> */}

      <div className="homeFooter">
        <span>
          © 2017-{year} <span id="footerLogo">swiftyvisas</span>. All rights
          reserved
        </span>
      </div>
    </>
  );
};

export default Profile;
